<template>
  <transition :appear="true" name="slide">
    <div class="container" :loading="loading">
      <div class="header-bg" :style="tp_style">
        <back class="back" back-url="/dbf-rank"></back>
        <!--        <div class="back" @click="back">-->
        <!--          <div class="icon"></div>-->
        <!--          <div class="name">返回</div>-->
        <!--        </div>-->
      </div>
      <div class="wrapper">
        <div class="user">
          <div class="avatar">
            <img :src="userAvatar" alt="">
          </div>
          <div class="info">
            <div class="name">{{ self.name || '-' }}</div>
            <div class="ranking">
              <div class="ico"></div>
              名次:<span :style="font_color">{{ self.sort || '-' }}</span>
            </div>
            <div class="num">
              集福数量:<span :style="font_color">{{ self.num || '-' }}</span><div class="ico" :style="font_img"></div>
            </div>
            <div class="time" :style="font_color2">
              <div class="ico" :style="font_color2"></div>
              {{ self.time || '-' }}
            </div>
          </div>
        </div>
        <!--排名-->
        <scroll ref="scroll" class="ranking-list" :data="list">
          <div class="scroll-block">
            <div class="title" :style="ban_style">
              <div class="rank-sort">排名</div>
              <div class="rank-user">用户</div>
              <div class="rank-num">集数</div>
              <div class="rank-time">时间</div>
            </div>
            <div class="rank-item" v-for="(item,index) in list" :key="index">
              <div
                v-if="item.sort === 1 || item.sort === 2 || item.sort === 3"
                :class="{first: item.sort === 1, second: item.sort === 2, third: item.sort === 3}"
              ></div>
              <div v-else class="rank-sort" style="color:#6A6A6A;">{{ item.sort }}</div>
              <div class="rank-user" style="color:#6A6A6A;">{{ item.name }}</div>
              <div class="rank-num" :style="font_color">
                {{ item.num }}
                <div class="ico" :style="font_img"></div>
              </div>
              <div class="rank-time" :style="font_color">{{ item.time }}</div>
            </div>
          </div>
        </scroll>
        <!--版权-->
        <div class="copyright">本活动最终解释权归内蒙古自治区图书馆所有</div>
      </div>
      <!-- <edit-userinfo v-if="editinfo" v-on:getCUserInfoNew="_getCUserInfoNew"></edit-userinfo> -->
      <edit-userinfo-agency v-if="editinfo || editagency" v-on:getCUserInfoNew="_getCUserInfoNew" :c_user_phone="c_user_phone" :c_user_name="c_user_name" :activity="activity" v-on:getuseragency="_useragency"></edit-userinfo-agency>
    </div>
  </transition>
</template>

<script>
import Back from '@/components/back/Back'
import Scroll from '@/components/scroll/scroll'
import { mapGetters } from 'vuex'
import { rank, useragency } from '@/api/Collects'
import { Toast } from 'vant'
import { getActivitiesDetail } from '@/api/Activity'
import { getCUserInfoNew } from '@/api/Cuser'
import EditUserinfoAgency from '@/components/activity/EditUserinfoAgency'
// import editUserinfo from '@/components/activity/EditUserinfo'

export default {
  name: 'DbfRank',
  components: {
    Back,
    Scroll,
    // editUserinfo,
    EditUserinfoAgency
  },
  computed: {
    ...mapGetters(['userGuid', 'userAvatar', 'agencyGuid'])
  },
  data () {
    return {
      list: [],
      self: {},
      activity_guid: '',
      activity: {},
      ban_style: '',
      font_img: '',
      font_color: '',
      tp_style: '',
      loading: false,
      font_color2: '',
      editinfo: false,
      editagency: false,
      c_user_name: '',
      c_user_phone: '',
      c_user_dzz: ''
    }
  },
  created () {
    console.log(this.$route.query)
    this.activity_guid = this.$route.query.activity_guid
    this._getActivitiesDetail()
    this._getCUserInfoNew()
  },
  mounted () {
    this._rank()
  },
  methods: {
    _useragency () {
      useragency({ c_user_guid: this.userGuid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          if (!res.data) {
            this.editagency = true
          } else {
            if (!res.data.collect_agency) {
              this.editagency = true
            } else {
              this.editagency = false
            }
          }
        }
      })
    },
    _getCUserInfoNew () {
      getCUserInfoNew({ c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          this.c_user_name = res.data.c_user_name
          this.c_user_phone = res.data.c_user_phone
          this.c_user_dzz = res.data.c_user_dzz
          if (!res.data.c_user_phone) {
            this.editinfo = true
          } else {
            this.editinfo = false
          }
        }
      })
    },
    _getActivitiesDetail () {
      let _this = this
      _this.loading = true
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getActivitiesDetail({ activity_guid: this.activity_guid }).then(res => {
        _this.loading = false
        Toast.clear()
        if (res.code === 200) {
          _this.activity = res.data
          _this._useragency()
          if (res.data.activitySetting.activity_color === 1) {
            _this.ban_style = 'background: linear-gradient(0deg, #31873A, #44A037, #31873A);'
            _this.font_color = 'color:#358C39'
            _this.font_color2 = 'color:#F8962A'
          } else if (res.data.activitySetting.activity_color === 2) {
            _this.ban_style = 'background: linear-gradient(0deg, #BB0815, #EB0505, #BD0814);'
            _this.font_color = 'color:#FF382A'
            _this.font_color2 = 'color:#F8962A'
          }
          _this.tp_style = 'background-image:url(' + res.data.activitySetting.activity_home_bg_img + ')'
          _this.font_img = 'background-image:url(' + res.data.activitySetting.activity_icon + ')'
        } else {
          _this.tp_style = "bg-image2('msyd-20230620/header-bg')"
        }
      })
    },
    _rank () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      rank({
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        activity_guid: this.activity_guid
      }).then(res => {
        const {
          code,
          data,
          msg
        } = res
        if (code === 200) {
          this.list = data.list
          this.self = data.self
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    back () {
      let index = this.$route.path.indexOf('/dbf-rank')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 249, 246, 1)

  .header-bg
    width 100%
    height 350px
    //bg-image2('msyd-20230620/header-bg')
    background-size 100% 100%
    background-repeat no-repeat

    .back
      background none

    //.back
    //  display flex
    //  align-items center
    //  width 160px
    //  height 84px
    //
    //  .icon
    //    width 16px
    //    height 28px
    //    margin-left 43px
    //    bg-image('~@/assets/img/reading/back-w')
    //    background-size 100% 100%
    //    background-repeat no-repeat
    //
    //  .name
    //    width 100px
    //    line-height normal
    //    font-size 26px
    //    font-weight 500
    //    color rgba(255, 255, 255, 1)
    //    margin-left 10px

  .wrapper
    position absolute
    top 318px
    left 0
    right 0
    bottom 0
    background rgba(245, 249, 246, 1)
    border-radius 38px 38px 0 0

    .user
      display flex
      align-items center
      width 680px
      height 144px
      margin -18px auto 0 auto
      padding 0 20px
      box-sizing border-box
      border-radius 12px
      background rgba(255, 255, 255, 1)

      .avatar
        width 100px
        height 100px
        border-radius 50%
        overflow hidden

        img
          width 100%
          height 100%

      .info
        display flex
        justify-content space-between
        align-items center
        flex-wrap wrap
        flex 1
        height 100px

        .name
          width 50%
          padding-left 24px
          box-sizing border-box
          font-size 30px
          color rgba(58, 58, 58, 1)
          no-wrap()

        .ranking
          display flex
          align-items center
          width 50%
          padding-left 24px
          box-sizing border-box
          font-size 28px
          color rgba(106, 106, 106, 1)

          .ico
            width 28px
            height 28px
            margin-right 18px
            bg-image2('msyd-20220603/icon-rank')
            background-size 100% 100%
            background-repeat no-repeat

        .num
          display flex
          align-items center
          width 50%
          padding-left 24px
          box-sizing border-box
          font-size 28px
          color rgba(132, 132, 132, 1)

          .ico
            width 32px
            height 34px
            margin-left 10px
            bg-image2('msyd-20230620/icon-f')
            background-size 100% 100%
            background-repeat no-repeat

        .time
          display flex
          align-items center
          width 50%
          padding-left 24px
          box-sizing border-box
          font-size 26px
          color rgba(55, 143, 57, 1)

          .ico
            width 26px
            height 26px
            margin-right 18px
            //bg-image2('msyd-20220718/icon-time') // 红色
            // bg-image2('msyd-20230620/icon-time') // 绿色
            background-image:url('https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/240116/ecdc2df5-fa0d-f059-d8b6-a817ef842979.png')
            background-size 100% 100%
            background-repeat no-repeat

    .ranking-list
      position absolute
      top 140px
      left 34px
      right 34px
      bottom 100px
      background rgba(255, 255, 255, 1)
      border-radius 12px
      overflow hidden

      .scroll-block
        width 100%
        padding-top 40px

        .title
          display flex
          align-items center
          width 648px
          height 78px
          margin 0 auto
          padding 0 20px
          box-sizing border-box
          // background: linear-gradient(0deg, #31873A, #44A037, #31873A);
          border-radius 38px
          color rgba(255, 255, 255, 1)
          font-size 30px

        .rank-item
          display flex
          align-items center
          width 608px
          height 92px
          margin 0 auto
          font-size 26px
          border-bottom 1px solid rgba(234, 234, 234, 1)

          &:last-child
            border none

        .rank-sort
          width 80px
          text-align center
          no-wrap()

        .rank-user
          width 180px
          text-align center
          no-wrap()

        .rank-num
          display flex
          align-items center
          justify-content center
          width 148px

          .ico
            width 44px
            height 46px
            margin-left 8px
            bg-image2('msyd-20230620/icon-f')
            background-size 100% 100%
            background-repeat no-repeat

        .rank-time
          width 200px
          text-align center
          no-wrap()

        .first
          width 32px
          height 40px
          margin 0 auto
          bg-image2('msyq-question/first-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .second
          width 32px
          height 40px
          margin 0 auto
          bg-image2('msyq-question/second-ico')
          background-size 100% 100%
          background-repeat no-repeat

        .third
          width 32px
          height 40px
          margin 0 auto
          bg-image2('msyq-question/third-ico')
          background-size 100% 100%
          background-repeat no-repeat

    .copyright
      position absolute
      bottom 0
      display flex
      align-items center
      justify-content center
      width 100%
      height 100px
      font-size 26px
      color rgba(58, 58, 58, 1)
</style>
